@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://unpkg.com/leaflet@1.7.1/dist/leaflet.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-sans bg-background text-white min-h-screen;
  }
}

#root {
  @apply min-h-screen;
}
